import { useEffect, useState } from 'react';
import axios from 'axios';
import { Circles } from 'react-loader-spinner';
import Modal from 'react-modal';
import './App.css';
import PartyVideoMp4 from './videos/party.mp4';
import PartyVideoMov from './videos/party.mov';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

function App() {
  const [email, setEmail] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);

  const submitForm = (e) => {
    e.preventDefault();
    setIsLoading(true);
    axios.post('https://sherewapi-backend.vercel.app/api/emails', { email })
      .then(function (_response) {
        setWasSubmitted(true);
        openModal();
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      }).finally(() => {
        setIsLoading(false);
      });
  }

  const openModal = () => {
    setIsOpen(true);
  }

  const closeModal = () => {
    setIsOpen(false);
  }

  return (
    <div className="App">
      <video id="video" muted autoPlay loop playsInline>
        <source src={PartyVideoMp4} type="video/mp4" />
        <source src={PartyVideoMov} type="video/mov" />
      </video>
      <div className="form-container">
        <h1>Sherewapi</h1>
        <h2>Never miss a party in Nairobi again</h2>
        <form onSubmit={submitForm}>
          <input placeholder='Email' name='email' onChange={(e) => setEmail(e.target.value)} type='text' />
          {isLoading ? <Circles
            height="35"
            width="35"
            color="#4fa94d"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass="loading"
            visible={true}
            /> : <button disabled={wasSubmitted} type="submit">Go!</button>}
        </form>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="submitted modal"
      >
        <h3>Thank you!</h3>

        <p>Sherewapi is not yet available but you will be the first to know as soon as we launch!</p>
      </Modal>
    </div>
  );
}

export default App;
